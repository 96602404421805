import React from 'react'
import { graphql } from 'gatsby'
import LocaleContext from '../contexts/LocaleContext'
import Layout from '../components/Layout'
import PostsList from '../components/PostsList'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Link from '../components/Link'
import blogPagesTypes from '../../blogPageTypes.json'
// import TagsList from '../components/TagsList'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

export const query = graphql`
  query($locale: String!, $postType: String!, $skip: Int!) {
    allDatoCmsPost(
      filter: { locale: { eq: $locale }, postType: { eq: $postType } },
      limit: 30, skip: $skip
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    datoCmsPress (locale: { eq: $locale }) {
      pressHeader
    }
    datoCmsNewMenu (locale: { eq: $locale }) {
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }){
      ...Footer
    }
  }
`

function BlogPosts({
  data: { allDatoCmsPost, datoCmsPress, datoCmsFooter, datoCmsNewMenu },
  pageContext,
}) {
  const page = pageContext.page
  const readablePostType = blogPagesTypes[pageContext.postType].slug

  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout locale={pageContext.locale}>
      <Menu
          transparent={false}
          menu={datoCmsNewMenu}
          socialMedia={datoCmsFooter.socialMedia}
        />
        <div>
          <HelmetDatoCms seo={allDatoCmsPost.seoMetaTags} />

          <section className="container center-width">
            <div className="prose">
              {readablePostType === 'press' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: datoCmsPress.pressHeader,
                  }}></div>
              ) : (
                <Heading />
              )}
              {!allDatoCmsPost.edges[0] && (
                <p>Det finns för tillfället inga inlägg att visa.</p>
              )}
              <PostsList posts={allDatoCmsPost.edges} />

              <div className="pagination flex--h-center">
                {page > 4 && (
                  <React.Fragment>
                    <Link to={`/${readablePostType}/1`} className="pagination__item pagination__first">⯬</Link>
                    <div className="pagination__item">...</div>
                  </React.Fragment>
                )}
                {page === 4 && <Link to={`/${readablePostType}/1`}  className="pagination__item">1</Link>}
                {page > 2 && <Link to={`/${readablePostType}/${page - 2}`}  className="pagination__item">{page - 2}</Link>}
                {page > 1 && <Link to={`/${readablePostType}/${page - 1}`}  className="pagination__item">{page - 1}</Link>}
                <div className="pagination__item pagination__item--active">{page}</div>
                {page < pageContext.noOfPages && <Link to={`/${readablePostType}/${page + 1}`}  className="pagination__item">{page + 1}</Link>}
                {page + 1 < pageContext.noOfPages && <Link to={`/${readablePostType}/${page + 2}`}  className="pagination__item">{page + 2}</Link>}
                {page + 3 === pageContext.noOfPages && <Link to={`/${readablePostType}/${page + 3}`} className="pagination__item">{page + 3}</Link>}

                {page + 3 < pageContext.noOfPages && (
                  <React.Fragment>
                    <div className="pagination__item">...</div>
                    <Link to={`/${readablePostType}/${pageContext.noOfPages}`} className="pagination__item pagination__last">⯮</Link>
                  </React.Fragment>
                )}
              </div>

            </div>
          </section>

        </div>
        <Footer footer={datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

const Heading = () => {
  const locale = React.useContext(LocaleContext).language
  return (
    <h1>
      {locale =="sv" ? "Nyhetsbrev" : "Newsletter" }
    </h1>
  )
}

export default BlogPosts
