import React from 'react'
import Img from 'gatsby-image'
import Link from '../components/Link'
import blogpostTypes from '../../blogPageTypes.json'
import { graphql } from 'gatsby'

function PostsList({ posts, grid }) {
  function getRecordTimestamp(record) {
    return new Date(record.node.date).getTime()
  }
  function sortRecordsByTime(a, b) {
    return getRecordTimestamp(b) - getRecordTimestamp(a)
  }

  return (
    <div>
      {posts.sort(sortRecordsByTime).map(({ node }) => (
        <div className="margin-top" key={node.id}>
          <div className="post">
            {node.featuredImage && (
              <Link
                to={`/${blogpostTypes[node.postType].slug}/${node.slug}`}
                className="post__image"
              >
                <Img fluid={node.featuredImage.fluid} objectFit="cover" />
              </Link>
            )}
            <div className="post__excerpt">
              <div className="unimp small margin-bottom-small">
                <small>{node.date}</small>
              </div>
              <Link
                to={`/${blogpostTypes[node.postType].slug}/${node.slug}`}
                className="post__link"
              >
                <h2>{node.title}</h2>
              </Link>
              <p>{node.excerpt}</p>
              <p className="flex post__tags">
                {node.tags.map(({ tag, slug }) => (
                  <Link
                    className="small post__tag"
                    key={slug}
                    to={`/tag/${slug}`}
                  >
                    #{tag} &nbsp;
                  </Link>
                ))}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const query = graphql`
  fragment BlogPost on DatoCmsPost {
    id
    title
    author
    date
    excerpt
    slug
    postType
    featuredImage {
      fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    tags {
      tag
      slug
    }
  }
`

export default PostsList
